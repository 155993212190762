<template>
  <div class="request-happiness-level">
      <span v-if="label" class="happiness-label">{{ label }}</span>

			<div class="feedback-emoji-container">
				<span class="feedback-emoji" :data-selected="value == 5" @click="select_happiness_level(5)"><img :src="`${publicPath}img/emojis/emoji-happiness-5.png`"></span>
				<span class="feedback-emoji" :data-selected="value == 4" @click="select_happiness_level(4)"><img :src="`${publicPath}img/emojis/emoji-happiness-4.png`"></span>
				<span class="feedback-emoji" :data-selected="value == 3" @click="select_happiness_level(3)"><img :src="`${publicPath}img/emojis/emoji-happiness-3.png`"></span>
				<span class="feedback-emoji" :data-selected="value == 2" @click="select_happiness_level(2)"><img :src="`${publicPath}img/emojis/emoji-happiness-2.png`"></span>
				<span class="feedback-emoji" :data-selected="value == 1" @click="select_happiness_level(1)"><img :src="`${publicPath}img/emojis/emoji-happiness-1.png`"></span>
			</div>
  </div>
</template>

<script>
export default {
	props: {
		label: { type: String },
		value: { type: Number }
	},

	data() {
		return { publicPath: process.env.BASE_URL, selected: null }
	},

	methods: {
		select_happiness_level(value) {
			// this.value = value;
			this.$emit('input', value);
		}
	}
}
</script>

<style lang="scss" scoped>
	.request-happiness-level{
		width: 100%;
    margin-bottom: 15px;
    padding: 20px 20px 10px 20px;
    border: 1px solid #ededed;
    background-color: rgb(253, 253, 253);
		border-radius: 5px;
		min-height: 80px;

		.happiness-label {
			display: block;
			margin-bottom: 5px;
			font-size: 17px;
			font-weight: 400;
		}

		.feedback-emoji-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding-bottom: 3px;

			span.feedback-emoji {
				display: inline-block;
				transition: font-size 0.1s ease-in-out;
				margin: 0 2px 0 2px;
				opacity: 0.4;
				max-width: 55px;
				cursor: pointer;

				&[data-selected], &:hover {
					opacity: 1;
				}

				img {
					width: 100%;
				}
			}
		}
	}
</style>