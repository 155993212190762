var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "page-title-container" },
    [
      _c(
        "el-col",
        { staticClass: "page-title", attrs: { sm: _vm.actionName ? 20 : 24 } },
        [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm.subtitle ? _c("p", [_vm._v(_vm._s(_vm.subtitle))]) : _vm._e()
        ]
      ),
      _vm.actionName
        ? _c(
            "el-col",
            { staticClass: "page-title-action", attrs: { sm: 4 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "secondary-color",
                  attrs: {
                    loading: _vm.actionLoading,
                    type: _vm.actionType || "primary"
                  },
                  on: { click: _vm.actionClicked }
                },
                [_vm._v(_vm._s(_vm.actionName))]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }