var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "request-happiness-level" }, [
    _vm.label
      ? _c("span", { staticClass: "happiness-label" }, [
          _vm._v(_vm._s(_vm.label))
        ])
      : _vm._e(),
    _c("div", { staticClass: "feedback-emoji-container" }, [
      _c(
        "span",
        {
          staticClass: "feedback-emoji",
          attrs: { "data-selected": _vm.value == 5 },
          on: {
            click: function($event) {
              return _vm.select_happiness_level(5)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: _vm.publicPath + "img/emojis/emoji-happiness-5.png" }
          })
        ]
      ),
      _c(
        "span",
        {
          staticClass: "feedback-emoji",
          attrs: { "data-selected": _vm.value == 4 },
          on: {
            click: function($event) {
              return _vm.select_happiness_level(4)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: _vm.publicPath + "img/emojis/emoji-happiness-4.png" }
          })
        ]
      ),
      _c(
        "span",
        {
          staticClass: "feedback-emoji",
          attrs: { "data-selected": _vm.value == 3 },
          on: {
            click: function($event) {
              return _vm.select_happiness_level(3)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: _vm.publicPath + "img/emojis/emoji-happiness-3.png" }
          })
        ]
      ),
      _c(
        "span",
        {
          staticClass: "feedback-emoji",
          attrs: { "data-selected": _vm.value == 2 },
          on: {
            click: function($event) {
              return _vm.select_happiness_level(2)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: _vm.publicPath + "img/emojis/emoji-happiness-2.png" }
          })
        ]
      ),
      _c(
        "span",
        {
          staticClass: "feedback-emoji",
          attrs: { "data-selected": _vm.value == 1 },
          on: {
            click: function($event) {
              return _vm.select_happiness_level(1)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: _vm.publicPath + "img/emojis/emoji-happiness-1.png" }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }